<template>
  <v-autocomplete class="c-input-xs" :class="{ 'c-input-has-sort': hasSort, ' input-filter': hasFilter }"
    v-model="value" :items="options" :label="label" :placeholder="placeholder" :prepend-inner-icon="sortPrependIcon"
    @click:prepend-inner="sortClick" @keyup.enter="onFilter" dense outlined clearable hide-details></v-autocomplete>
</template>

<script>
import { debounce } from "lodash/function";

export default {
  name: 'SelectMaterialWarehouse',
  props: {
    hasSort: {
      type: Boolean,
      default: () => false
    },
    hasFilter: {
      type: Boolean,
      default: () => true
    },
    name: {
      type: String,
      default: () => ''
    },
    sortName: {
      type: String,
      default: () => ''
    },
    sorting: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    showNotWarehouse: {
      type: Boolean,
      default: () => false
    },
    autoSelect: {
      type: Boolean,
      default: () => true
    },
    /*options: {
      type: Array,
      default: () => []
    },*/
  },
  data: () => ({
    value: null,
    sortValue: null,
    filters: {},
    options: []
  }),
  watch: {
    value() {
      this.onFilter()
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null
      }
    }
  },
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return ''
      }
      switch (this.sortValue) {
        case 'asc':
          return 'mdi-arrow-up'
        case 'desc':
          return 'mdi-arrow-down'
        default:
          return 'mdi-arrow-up-down'
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit('onFilter', {
        name: this.name,
        value: this.value
      })
    }, 500),
    getList() {
      const { material_warehouses } = window.me
      this.options = [...material_warehouses].map(w => ({
        value: w.id,
        text: w.code || ''
      }))
      if (material_warehouses.length === 1 && this.autoSelect) {
        this.value = material_warehouses[0].id
      }
    },
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = 'asc'
      } else if (this.sortValue === 'asc') {
        this.sortValue = 'desc'
      } else if (this.sortValue === 'desc') {
        this.sortValue = 'asc'
      }
      this.$emit('onSort', {
        sort_by: this.sortName,
        sort_type: this.sortValue
      })
    }
  },
}
</script>
